<!--
 * @Description: 测试页面
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-05-24 11:25:24
 * @LastEditors: zhoucheng
-->
<template>
  <div class=''
       style="padding-top: 30px">
    <van-field v-model="order"
               placeholder="请输入单号" />
    <van-row>
      <van-button @click="wxPay">微信支付</van-button>
      <van-button @click="zfbPay">支付宝支付</van-button>
      <van-button @click="qrCode">调起二维码</van-button>
      <moveBox right="10px"
               top="300px"
               endRight="10px">
        <van-image :src="require('@/assets/homePage/backCar.png')"></van-image>
      </moveBox>
    </van-row>
    <van-row style="margin-top:10px;">
      <drag-verify ref="dragVerify"
                   :width="360"
                   :height="40"
                   progressBarBg="#FFA500"
                   background="#F5F5F5"
                   :isPassing.sync="isPassing"
                   text="请按住滑块拖动"
                   successText="验证通过"
                   handlerIcon="el-icon-d-arrow-right"
                   successIcon="el-icon-circle-check"
                   @passcallback="passcallback2">
      </drag-verify>
    </van-row>
    <!-- 图片缺口滑块 -->
    <van-row>
      <Vcode :show="isShow"
             @success="success" />
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import moveBox from '@/components/moveBox'
import JcRange from '@/components/JcRange.vue'
import Vcode from 'vue-puzzle-vcode'// 页面内引用插件
export default {

  // import引入的组件需要注入到对象中才能使用
  components: {
    moveBox,
    'drag-verify': JcRange,
    Vcode
  },
  data () {
    // 这里存放数据
    return {
      order: '',
      disX: 0,
      status: false,
      isShow: true
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    window.payBack = function () {
      console.log('payBack被调用')
    }
    window.getQRCodeInfo = function (s) {
      console.log('sss', s)
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    success () {
      this.isShow = false // 通过验证后，手动隐藏模态框
    },
    // 滑动验证
    passcallback2 () {
      console.log('滑块验证成功的方法')
    },
    // 重置滑块
    reset () {
      console.log('重置滑块')
      this.isPassing = false
      if (this.$refs.dragVerify !== undefined) {
        this.$refs.dragVerify.reset()
      }
    },
    // 调起二维码
    qrCode () {
      window.android.scanQRCode()
    },
    // 微信支付
    wxPay () {
      const info = {
        description: '渝北支付测试',
        orderId: this.order,
        amount: '1'
      }
      this.$choosePayStyle.wxAppPay(info).then(res => {
        console.log('wxpay', res)
        if (window.android) {
          window.android.requestWxPay(res.resultEntity.prepayid, res.resultEntity.nonceStr, res.resultEntity.timeStamp, res.resultEntity.sign)
        } else if (window.webkit) {
          let str = {
            prepayid: res.resultEntity.prepayid,
            nonceStr: res.resultEntity.nonceStr,
            timeStamp: res.resultEntity.timeStamp,
            sign: res.resultEntity.sign,
            package: res.resultEntity.package
          }
          str = JSON.stringify(str)
          window.webkit.messageHandlers.requestWxPay.postMessage(str)
        }
      }).catch(err => {
        console.log('wxerr', err)
      })
    },
    // 支付宝支付
    zfbPay () {
      const info = {
        description: '渝北支付测试',
        orderId: this.order,
        amount: '1'
      }
      this.$choosePayStyle.zfbAliPay(info).then(res => {
        console.log('alires', res)
        if (window.android) {
          window.android.requestAliPay(res.resultEntity)
        } else if (window.webkit) {
          window.webkit.messageHandlers.requestAliPay.postMessage(res.resultEntity)
        }
      }).catch(err => {
        console.log('alierr', err)
      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@mixin jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jc-component__range {
  border-radius: 4px;
  overflow: hidden;
  .jc-range {
    background-color: #ebebeb;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #333;
    @include jc-flex;
    height: 40px; /*no*/
    &.success {
      background-color: #7ac23c;
      color: #fff;
      i {
        color: #7ac23c;
      }
    }
    i {
      position: absolute;
      left: 0;
      width: 50px; /*no*/
      height: 100%;
      color: #919191;
      background-color: #fff;
      border: 1px solid #bbb;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      @include jc-flex;
    }
  }
}
</style>
